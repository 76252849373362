import React from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import ButtonReEnroll from './ButtonReEnroll';

const CERT_BASE_PATH =
  'https://static-content.fedsafe.com/student-certificates/';

const CompleteTab = ({ courses, anyDashboardRecords }) => {
  const renderList = () => {
    return courses.map((course) => {
      return (
        <div className="item" key={course.userCourseEnrollmentId}>
          <div className="right floated content">
            <a
              className="ui primary button"
              href={`${CERT_BASE_PATH}${course.certificatePath}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="download icon" style={{ marginLeft: '4px' }}></i>
            </a>
          </div>
          <div className="header">{course.displayName}</div>
          <div className="description">{formatDate(course.completeTime)}</div>
        </div>
      );
    });
  };

  const formatDate = (date) => {
    const utcDate = Date.parse(date);
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcToZonedTime(utcDate, timeZone);
    return format(localDate, 'MMMM d, yyyy');
  };

  return (
    <>
      {courses.length === 0 && (
        <p>
          Certificates for completed courses will be available for download
          here.
        </p>
      )}
      {courses.length > 0 && (
        <div className="ui middle aligned divided list">{renderList()}</div>
      )}
      <br />
      <ButtonReEnroll anyDashboardRecords={anyDashboardRecords} />
      <br />
    </>
  );
};

export default CompleteTab;
